<template>
    <b-navbar-nav class="align-items-center ml-auto ml-md-0">
        <base-dropdown menu-on-right class="nav-item" tag="li" title-tag="a" title-classes="nav-link pr-0">
            <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
                <b-media no-body class="align-items-center">
                    <b-media-body class="ml-2 d-none d-lg-block">
      <span class="mb-0 text-sm  font-weight-bold" v-html="currentUser.name"></span>
                    </b-media-body>
                </b-media>
            </a>

            <template>
                <b-dropdown-item @click="viewProfile(currentUser)">
                    <i class="ni ni-circle-08"></i>
                    <span>Profile</span>
                </b-dropdown-item>
                <div class="dropdown-divider"></div>
                <b-dropdown-item @click="ChangePassword(currentUser)">
                    <i class="ni ni-key-25"></i>
                    <span>Change Password</span>
                </b-dropdown-item>
                <b-dropdown-item @click="logoutClicked(currentUser)">
                    <i class="ni ni-user-run"></i>
                    <span>Logout</span>
                </b-dropdown-item>

            </template>
        </base-dropdown>

        <b-modal title="Change Password" id="changePassword" no-close-on-esc hide-footer>
            <change-password :user_id="currentUser.id" @success="ModalClose"></change-password>
        </b-modal>
    </b-navbar-nav>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import urls from '@/data/urls';
import ChangePassword from '@/views/auth/changePassword';

export default {
    components: { ChangePassword },
    computed: {
        ...mapGetters(['currentUser'])
    },
    methods: {
        ...mapActions(['signOut']),
        logoutClicked () {
            this.signOut();
            axios.get(urls.auth.logout);
            this.$router.push('/login/');
        },
        ChangePassword () {
            this.$bvModal.show('changePassword');
        },
        ModalClose () {
            this.$bvModal.hide('changePassword');
            this.$router.push('/login/');
        },
        viewProfile (currentUser) {
            const id = currentUser.id;
            if (currentUser.role === 'Super Admin') {
                this.$router.push({ path: '/admin/user/' + id + '/details/' });
            } else if (currentUser.role === 'Shop Admin') {
                this.$router.push({ path: '/shop-admin/' + id + '/details/' });
            } else if (currentUser.role === 'User') {
                this.$router.push({ path: '/user/details/' + id + '/' });
            } else {
                this.$router.push({ path: '*' });
            }
        }
    }
};
</script>
